html {
  /* overflow-y: hidden; */
}
.navCrypto {
  background-color: #f1f2f6;
}
.navCrypto-mobile {
  display: flex;
}
.navCrypto-desktop {
  display: none;
}
.navCrypto__cf,
.navCrypto__btnCont {
  width: 50vw;
  /* background-color: green; */
  padding: 1rem 2rem;
}
.navCrypto__cfCoin {
  display: flex;
}
.navCrypto__cfCoin img {
  width: 5rem;
  object-fit: contain;
}
.navCrypto__cfCoin__text {
  padding: 1rem 0 1rem 0.5rem;
}
.navCrypto__cfCoin__text-name {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;
  color: #2f3542;
}
.navCrypto__cfCoin__text-name img {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.5rem;
  object-fit: contain;
}
.navCrypto__cfCoin__text-price {
  margin-bottom: 0;
  font-size: 1.8rem;
  color: #2f3542;
  margin-top: -0.5rem;
}
.navCrypto__btnCont {
  position: relative;
}
.navCrypto__btnCont button {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  background-color: #ffa502;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0.8rem 1.2rem;
  border-radius: 4px;
  transition: all 0.5s ease;
}
.navCrypto__btnCont button:hover {
  background-color: #eccc68;
  border: none;
}
.navCrypto-collapse {
  /* background-color: red; */
  padding: 0.5rem 0;
}
.navCrypto__coins {
  width: 100vw;
}
.navCrypto__coin {
  width: 25vw;
  padding: 1rem 0.5rem;
  text-align: center;
  /* background-color: white; */
  display: inline-block;
}
.navCrypto__coin-name {
  font-weight: 600;
  font-size: 1.4rem;
  color: #2f3542;
}
.navCrypto__coin-price span {
  font-weight: 600;
}
.navCrypto__coin-price {
  font-size: 1.4rem;
  margin: 0.3rem 0 0.5rem 0;
  color: #57606f;
}
.navCrypto__coin-img img {
  width: 2.8rem;
  height: 2.8rem;
  object-fit: contain;
}
.text--green {
  color: #2ed573;
}
.text--red {
  color: #ff4757;
}

@media (min-width: 480px) {
    .navCrypto__coin-name {
        font-size: 1.6rem;
      }
      .navCrypto__coin-price {
        font-size: 1.6rem;
      }
      .navCrypto__coin-img img {
          transform: scale(1.1);
      }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
    .navCrypto__coin {
        padding: 1.5rem;
    }
    .navCrypto__coin-name {
        font-size: 1.8rem;
      }
      .navCrypto__coin-price {
        font-size: 1.8rem;
      }
      .navCrypto__coin-img img {
          transform: scale(1.2);
      }
}
@media (min-width: 1200px) {
  .navCrypto-mobile {
    display: none;
  }
  .navCrypto-desktop {
    display: inline-block;
    padding: 0.5rem 0rem;
  }
  .navCrypto__coins {
    display: flex;
  }
  .navCrypto__coin {
    width: 5vw;
    padding: 1rem 0;
  }
  .navCrypto__divider {
    width: 0.5vw;
    text-align: center;
    position: relative;
  }
  .line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 40%;
    background-color: #333;
  }
  .navCrypto__coin-name {
    font-size: 1.1rem;
  }
  .navCrypto__coin-price {
    font-size: 1.2rem;
  }
  .navCrypto__coin-img img {
    transform: scale(0.8);
  }
  .navCrypto__lead {
    width: 13vw;
    position: relative;
  }
  .navCrypto__cfCoin {
    justify-content: center;
    position: absolute;
    width: 10vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 1920px) {
  .navCrypto-desktop {
    width: 100vw;
    justify-content: center;
    padding: 1rem 0;
  }
  .navCrypto__divider {
    width: 0.5vw;
  }
  .navCrypto__lead {
    width: 13vw;
  }
  .navCrypto__coin-name {
    font-size: 1.8rem;
  }
  .navCrypto__coin-price {
    font-size: 1.6rem;
  }
  .navCrypto__coin-img img {
    transform: scale(0.9);
  }
  .navCrypto__cfCoin img {
    transform: scale(1.2);
  }
  .navCrypto__cfCoin__text {
    padding-left: 1rem;
  }
  .navCrypto__cfCoin__text-name {
    font-size: 2.5rem;
  }
  .navCrypto__cfCoin__text-name img {
    transform: scale(1.2);
  }
  .navCrypto__cfCoin__text-price {
    font-size: 2.5rem;
  }
}
@media (min-width: 2560px) {
  .navCrypto__coin-name {
    font-size: 2rem;
  }
  .navCrypto__coin-price {
    font-size: 1.9rem;
    margin: 0.8rem 0;
  }
  .navCrypto__coin-img img {
    transform: scale(1.1);
  }
  .line {
    height: 40%;
  }
}
