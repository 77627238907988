@media (min-width: 1200px) { 
    .News-scroll {
        height: 60em;
        overflow-y: scroll; 
        margin-bottom: 40px;
    }
 }

 .iconNews {
     width: 3.5rem;
 }