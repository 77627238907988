.slider-wrapper {
  height: 40vh;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -khtml-border-radius: 20px;
}
.slide img {
  height: 40vh;
  width: 100vh;
  object-fit: contain;
}
[class*='col-'] {
  padding: 0 5px 0 5px;
}
@media (min-width: 480px) {
  .slider-wrapper,
  .slide img {
    height: 90vh;
  }
  [class*='col-'] {
    padding: 0 15px 0 15px;
  }
}
@media (min-width: 768px) {
  .slider-wrapper,
  .slide img {
    height: 40vh;
  }
  [class*='col-'] {
    padding: 0 10px 0 10px;
  }
}
@media (min-width: 992px) {
  .slider-wrapper,
  .slide img {
    height: 25em;
  }
}
@media (min-width: 1200px) {
  .slider-wrapper,
  .slide img {
    height: 25em;
  }
}
@media (min-width: 1920px) {
  .slider-wrapper,
  .slide img {
    height: 25em;
  }
}
@media (min-width: 2560px) {
  .slider-wrapper,
  .slide img {
    height: 25em;
  }
}
