
.footerLinkIcon {
    font-size: 2em;
    cursor: pointer;
    transition: all .5s;
    color: #fff;
    margin-right: 2.3rem;
    margin-bottom: 3rem;
}


.footerLinkIcon:hover {
    transform: scale(1.2);
}