.slider-wrapper {
  height: 40vh;
  border-radius: 20px;
  -khtml-border-radius: 20px;
}
.slide img {
  height: 40vh;
  width: 100vh;
  object-fit: contain;
}
[class*='col-'] {
  padding: 0 5px 0 5px;
}
@media (min-width: 480px) {
  .slider-wrapper,
  .slide img {
    height: 90vh;
  }
  [class*='col-'] {
    padding: 0 15px 0 15px;
  }
}
@media (min-width: 768px) {
  .slider-wrapper,
  .slide img {
    height: 40vh;
  }
  [class*='col-'] {
    padding: 0 10px 0 10px;
  }
}
@media (min-width: 992px) {
  .slider-wrapper,
  .slide img {
    height: 25em;
  }
}
@media (min-width: 1200px) {
  .slider-wrapper,
  .slide img {
    height: 25em;
  }
}
@media (min-width: 1920px) {
  .slider-wrapper,
  .slide img {
    height: 25em;
  }
}
@media (min-width: 2560px) {
  .slider-wrapper,
  .slide img {
    height: 25em;
  }
}

.Newtop {
  margin: 0em 1.4em;
}
.Newtop-row {
  display: flex;
}
.Newtop-col-20 {
  position: relative;
  height: 25em;
  border-radius: 15px;
  margin: 0.4em;
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
}
.Newtop-high {
  margin: 0em 0.8em;
  position: absolute;
  bottom: 0.5em;
  z-index: 2;
}
.Newtop-icon {
  width: 1.5em;
  margin-bottom: -1.5em;
}
.Newtop-high h1 {
  color: #fff;
  font-size: 1.2em;
  overflow-wrap: break-word;
  line-height: 1.5;
}
.Newtop-col-40 {
  height: auto;
  border-radius: 15px;
  margin: 0.4em;
}

.Newtop-hover {
  /* for HOVER */

  /* height: 100%;
  width: 100%;
  left: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  color: white;
  padding: 20px 20px;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.6s ease;
  overflow-y: auto;
  bottom: -56.8%;
  opacity: 0; */

  /* for NON HOVER */
  height: 100%;
  width: 100%;
  left: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  color: white;
  padding: 20px 20px;
  font-family: 'Montserrat', sans-serif;
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  overflow-y: auto;
  bottom: 0%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
  border-radius: 15px;
}

.Newtop-hover a {
  color: #fff;
  text-decoration: underline;
  font-weight: 700;
  text-transform: capitalize;
}

/* APPLY this CLASS for hover */

/* .Newtop-col-20:hover .Newtop-hover {
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 1;
  border-radius: 15px;
  transition: all 0.6s ease;
  z-index: 100;
} */

.Newtop-slide {
  height: 100%;
  width: 100%;
}

.tile-slide {
  display: none;
  flex-grow: 1;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  border-radius: 10px;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.tile-slide.sactive {
  display: flex;
}
.Newtop-arrow {
  position: absolute;
  display: flex;
  color: white;
  justify-content: space-between;
  z-index: 11;
  top: 42%;
  padding: 0em 1em;
}



@media (max-width: 320px) {
  .Newtop-phone {
    margin: .5em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 90%
  }
  .Newtop-arrow img {
    width: 3em;
  }
  .Arrow-right {
    margin-right: 0em;
  }
  
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (min-width: 321px) and (max-width: 424.98px) {
  .Newtop-phone {
    margin: .5em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 92%
  }
  .Newtop-arrow img {
    width: 3em;
  }
}

@media (min-width: 425px) and (max-width: 575.98px) {
  .Newtop-phone {
    margin: 0em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow img {
    width: 4.5em;
  }
  .Newtop-arrow {
    width: 94%
  }
  .Newtop-arrow img {
    width: 3em;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .Newtop-phone {
    margin: 0em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 96%
  }
  .Newtop-arrow img {
    width: 4em;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .Newtop-phone {
    margin: 0em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 96%
  }
  .Newtop-arrow img {
    width: 4em;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Newtop-phone {
    margin: 0em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 1em auto 1em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 97%
  }
  .Newtop-arrow img {
    width: 5em;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .Newtop-phone {
    display: none;
  }
  .Newtop-col-40 {
    width: 40%;
  }
  .Newtop-col-20 {
    width: 20%;
  }
  .Arrow-right {
    margin-left: 40em;
  }
}

@media (min-width: 1440px) and (max-width: 1919.98px) { 
  .Newtop-phone {
    display: none;
  }
  .Newtop-col-40 {
    width: 40%;
  }
  .Newtop-col-20 {
    width: 20%;
  }
  .Newtop-arrow {
    top: 45%;
    width: 37.7%;
  }
  .Newtop-arrow img {
    width: 4em;
  }
}

@media (min-width: 1920px) {
  .Newtop-phone {
    display: none;
  }
  .Newtop-col-40 {
    width: 40%;
  }
  .Newtop-col-20 {
    width: 20%;
  }
  .Newtop-arrow {
    top: 42%;
    width: 38.2%;
  }
  .Newtop-arrow img {
    width: 5em;
  }
}



.footerLinkIcon {
    font-size: 2em;
    cursor: pointer;
    -webkit-transition: all .5s;
    transition: all .5s;
    color: #fff;
    margin-right: 2.3rem;
    margin-bottom: 3rem;
}


.footerLinkIcon:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
@media (min-width: 1200px) { 
    .Social-scroll {
        height: 54em;
        position: absolute;
        overflow-y: scroll;
        margin-bottom: 40px;
    }
 }
@media (min-width: 1200px) { 
    .News-scroll {
        height: 60em;
        overflow-y: scroll; 
        margin-bottom: 40px;
    }
 }

 .iconNews {
     width: 3.5rem;
 }
html {
  /* overflow-y: hidden; */
}
.navCrypto {
  background-color: #f1f2f6;
}
.navCrypto-mobile {
  display: flex;
}
.navCrypto-desktop {
  display: none;
}
.navCrypto__cf,
.navCrypto__btnCont {
  width: 50vw;
  /* background-color: green; */
  padding: 1rem 2rem;
}
.navCrypto__cfCoin {
  display: flex;
}
.navCrypto__cfCoin img {
  width: 5rem;
  object-fit: contain;
}
.navCrypto__cfCoin__text {
  padding: 1rem 0 1rem 0.5rem;
}
.navCrypto__cfCoin__text-name {
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;
  color: #2f3542;
}
.navCrypto__cfCoin__text-name img {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.5rem;
  object-fit: contain;
}
.navCrypto__cfCoin__text-price {
  margin-bottom: 0;
  font-size: 1.8rem;
  color: #2f3542;
  margin-top: -0.5rem;
}
.navCrypto__btnCont {
  position: relative;
}
.navCrypto__btnCont button {
  position: absolute;
  top: 50%;
  right: 2rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #ffa502;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 0.8rem 1.2rem;
  border-radius: 4px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.navCrypto__btnCont button:hover {
  background-color: #eccc68;
  border: none;
}
.navCrypto-collapse {
  /* background-color: red; */
  padding: 0.5rem 0;
}
.navCrypto__coins {
  width: 100vw;
}
.navCrypto__coin {
  width: 25vw;
  padding: 1rem 0.5rem;
  text-align: center;
  /* background-color: white; */
  display: inline-block;
}
.navCrypto__coin-name {
  font-weight: 600;
  font-size: 1.4rem;
  color: #2f3542;
}
.navCrypto__coin-price span {
  font-weight: 600;
}
.navCrypto__coin-price {
  font-size: 1.4rem;
  margin: 0.3rem 0 0.5rem 0;
  color: #57606f;
}
.navCrypto__coin-img img {
  width: 2.8rem;
  height: 2.8rem;
  object-fit: contain;
}
.text--green {
  color: #2ed573;
}
.text--red {
  color: #ff4757;
}

@media (min-width: 480px) {
    .navCrypto__coin-name {
        font-size: 1.6rem;
      }
      .navCrypto__coin-price {
        font-size: 1.6rem;
      }
      .navCrypto__coin-img img {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
      }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
    .navCrypto__coin {
        padding: 1.5rem;
    }
    .navCrypto__coin-name {
        font-size: 1.8rem;
      }
      .navCrypto__coin-price {
        font-size: 1.8rem;
      }
      .navCrypto__coin-img img {
          -webkit-transform: scale(1.2);
                  transform: scale(1.2);
      }
}
@media (min-width: 1200px) {
  .navCrypto-mobile {
    display: none;
  }
  .navCrypto-desktop {
    display: inline-block;
    padding: 0.5rem 0rem;
  }
  .navCrypto__coins {
    display: flex;
  }
  .navCrypto__coin {
    width: 5vw;
    padding: 1rem 0;
  }
  .navCrypto__divider {
    width: 0.5vw;
    text-align: center;
    position: relative;
  }
  .line {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 1px;
    height: 40%;
    background-color: #333;
  }
  .navCrypto__coin-name {
    font-size: 1.1rem;
  }
  .navCrypto__coin-price {
    font-size: 1.2rem;
  }
  .navCrypto__coin-img img {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  .navCrypto__lead {
    width: 13vw;
    position: relative;
  }
  .navCrypto__cfCoin {
    justify-content: center;
    position: absolute;
    width: 10vw;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
@media (min-width: 1920px) {
  .navCrypto-desktop {
    width: 100vw;
    justify-content: center;
    padding: 1rem 0;
  }
  .navCrypto__divider {
    width: 0.5vw;
  }
  .navCrypto__lead {
    width: 13vw;
  }
  .navCrypto__coin-name {
    font-size: 1.8rem;
  }
  .navCrypto__coin-price {
    font-size: 1.6rem;
  }
  .navCrypto__coin-img img {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  .navCrypto__cfCoin img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .navCrypto__cfCoin__text {
    padding-left: 1rem;
  }
  .navCrypto__cfCoin__text-name {
    font-size: 2.5rem;
  }
  .navCrypto__cfCoin__text-name img {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .navCrypto__cfCoin__text-price {
    font-size: 2.5rem;
  }
}
@media (min-width: 2560px) {
  .navCrypto__coin-name {
    font-size: 2rem;
  }
  .navCrypto__coin-price {
    font-size: 1.9rem;
    margin: 0.8rem 0;
  }
  .navCrypto__coin-img img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .line {
    height: 40%;
  }
}

.mainNav {
  padding: 1rem 0;
  background-color: black;
  display: flex;
  width: 100%;
}
.mainNav__toggler {
  width: 15vw;
  text-align: center;
  height: 8vh;
}
.mainNav__toggler i {
  color: white;
  font-size: 2.6rem !important;
  line-height: 8vh;
  cursor: pointer;
}
.mainNav__logo {
  width: 22.5vw;
  padding: 1rem;
}
.mainNav__logo img {
  width: 100%;
  height: 5vh;
  object-fit: contain;
}

.mainNav__text {
  position: relative;
  width: 40vw;
  /* border: 1px solid red; */
}
.mainNav__text p {
  /* border: 1px solid green; */
  margin: 0;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 1.4rem;
  font-weight: 500;
}
.navbar-nav li a {
  font-weight: 500;
  font-size: 1.5rem;
  color: #333;
}
@media (min-width: 480px) {
  .mainNav {
    padding: 2rem 0;
  }
  .mainNav__toggler {
    height: 18vh;
  }
  .mainNav__toggler i {
    line-height: 18vh;
  }
  .mainNav__text p {
    font-size: 1.9rem;
  }
  .Nav-phone {
    padding: 1rem 2rem 0.5rem 2rem;
  }
  .mainNav__logo img {
    height: 14vh;
  }
}
@media (min-width: 768px) {
  .mainNav {
    padding: 1rem 0;
  }
  .mainNav__toggler {
    height: 10vh;
  }
  .mainNav__toggler i {
    line-height: 12vh;
    font-size: 3.5rem !important;
  }
  .mainNav__text p {
    font-size: 2.3rem;
  }
  .mainNav__logo img {
    height: 10vh;
  }
}
@media (min-width: 992px) {
  .mainNav__toggler i {
    line-height: 9vh;
    font-size: 3.5rem !important;
  }
  .mainNav__logo img {
    height: 8vh;
  }
  .mainNav__text p {
    font-size: 2.8rem;
  }
}
@media (min-width: 1200px) {
  .mainNav__toggler {
    width: 10vw;
  }
  .mainNav__text {
    width: 50vw;
  }
  .mainNav__logo {
    width: 20vw;
  }
  .mainNav__text p {
    font-size: 2.5rem;
  }
  .Newtop-desktop {
    padding-top: 1rem;
  }
  .navbar-nav li a {
    font-size: 1.7rem;
  }
  
}
@media (min-width: 1920px) {
  .mainNav__toggler i {
    font-size: 4rem !important;
  }
  .mainNav__text p {
    font-size: 3.2rem;
  }
}
@media (min-width: 2560px) {
}

