.Newtop {
  margin: 0em 1.4em;
}
.Newtop-row {
  display: flex;
}
.Newtop-col-20 {
  position: relative;
  height: 25em;
  border-radius: 15px;
  margin: 0.4em;
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
}
.Newtop-high {
  margin: 0em 0.8em;
  position: absolute;
  bottom: 0.5em;
  z-index: 2;
}
.Newtop-icon {
  width: 1.5em;
  margin-bottom: -1.5em;
}
.Newtop-high h1 {
  color: #fff;
  font-size: 1.2em;
  overflow-wrap: break-word;
  line-height: 1.5;
}
.Newtop-col-40 {
  height: auto;
  border-radius: 15px;
  margin: 0.4em;
}

.Newtop-hover {
  /* for HOVER */

  /* height: 100%;
  width: 100%;
  left: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  color: white;
  padding: 20px 20px;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.6s ease;
  overflow-y: auto;
  bottom: -56.8%;
  opacity: 0; */

  /* for NON HOVER */
  height: 100%;
  width: 100%;
  left: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  color: white;
  padding: 20px 20px;
  font-family: 'Montserrat', sans-serif;
  transition: all 0.6s ease;
  overflow-y: auto;
  bottom: 0%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
  border-radius: 15px;
}

.Newtop-hover a {
  color: #fff;
  text-decoration: underline;
  font-weight: 700;
  text-transform: capitalize;
}

/* APPLY this CLASS for hover */

/* .Newtop-col-20:hover .Newtop-hover {
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 1;
  border-radius: 15px;
  transition: all 0.6s ease;
  z-index: 100;
} */

.Newtop-slide {
  height: 100%;
  width: 100%;
}

.tile-slide {
  display: none;
  flex-grow: 1;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  border-radius: 10px;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}
.tile-slide.sactive {
  display: flex;
}
.Newtop-arrow {
  position: absolute;
  display: flex;
  color: white;
  justify-content: space-between;
  z-index: 11;
  top: 42%;
  padding: 0em 1em;
}



@media (max-width: 320px) {
  .Newtop-phone {
    margin: .5em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 90%
  }
  .Newtop-arrow img {
    width: 3em;
  }
  .Arrow-right {
    margin-right: 0em;
  }
  
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (min-width: 321px) and (max-width: 424.98px) {
  .Newtop-phone {
    margin: .5em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 92%
  }
  .Newtop-arrow img {
    width: 3em;
  }
}

@media (min-width: 425px) and (max-width: 575.98px) {
  .Newtop-phone {
    margin: 0em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow img {
    width: 4.5em;
  }
  .Newtop-arrow {
    width: 94%
  }
  .Newtop-arrow img {
    width: 3em;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .Newtop-phone {
    margin: 0em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 96%
  }
  .Newtop-arrow img {
    width: 4em;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .Newtop-phone {
    margin: 0em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 0.5em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 96%
  }
  .Newtop-arrow img {
    width: 4em;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .Newtop-phone {
    margin: 0em 1em 0em 1em;
    }
  .Newtop-desktop {
    display: none;
  }
  .Newtop-col-40 {
    margin: 1em auto 1em auto;
    display: table;
    width: 100%;
  }
  .Newtop-col-20 {
    margin: .5em 0em;
  }
  .Newtop-arrow {
    width: 97%
  }
  .Newtop-arrow img {
    width: 5em;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .Newtop-phone {
    display: none;
  }
  .Newtop-col-40 {
    width: 40%;
  }
  .Newtop-col-20 {
    width: 20%;
  }
  .Arrow-right {
    margin-left: 40em;
  }
}

@media (min-width: 1440px) and (max-width: 1919.98px) { 
  .Newtop-phone {
    display: none;
  }
  .Newtop-col-40 {
    width: 40%;
  }
  .Newtop-col-20 {
    width: 20%;
  }
  .Newtop-arrow {
    top: 45%;
    width: 37.7%;
  }
  .Newtop-arrow img {
    width: 4em;
  }
}

@media (min-width: 1920px) {
  .Newtop-phone {
    display: none;
  }
  .Newtop-col-40 {
    width: 40%;
  }
  .Newtop-col-20 {
    width: 20%;
  }
  .Newtop-arrow {
    top: 42%;
    width: 38.2%;
  }
  .Newtop-arrow img {
    width: 5em;
  }
}

